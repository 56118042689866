import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { Search, Calendar, Clock, TrendingUp, MessageCircle } from 'lucide-react';
import { Helmet } from 'react-helmet';
import CarouselTransition from '../../Components/CarouselTransition';

const getCurrentDateTime = () => {
  const today = new Date();
  return today.toLocaleString('es-ES', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit', 
    hour: '2-digit',
    minute: '2-digit',
  }).replace(',', '');
};

const PredictionCard = ({ prediction }) => {
  const statusColors = {
    ganada: 'bg-green-500',
    pendiente: 'bg-orange-500',
    perdida: 'bg-red-500',
    nula: 'bg-white',
  };

  return (
    <div className="relative bg-gray-800 rounded-lg shadow-lg overflow-hidden">
      <div className={`absolute inset-y-0 right-0 w-2 ${statusColors[prediction.state] || 'bg-gray-500'}`}></div>
      <div className="p-6">
        <div className="flex justify-between items-center mb-4">
          <div className="flex items-center space-x-4">
            <img src={prediction.homeTeam.logo} alt={prediction.homeTeam.name} className="w-12 h-12 rounded-full" />
            <span className="text-white font-semibold">vs</span>
            <img src={prediction.awayTeam.logo} alt={prediction.awayTeam.name} className="w-12 h-12 rounded-full" />
          </div>
          <div className="bg-yellow-500 text-gray-900 font-bold py-1 px-3 rounded-full text-sm">
            {prediction.odds.toFixed(2)}
          </div>
        </div>
        <div className="text-center mb-4">
          <h3 className="text-white font-bold text-lg">{prediction.homeTeam.name} vs {prediction.awayTeam.name}</h3>
          <p className="text-gray-400 text-sm">{prediction.prediction}</p>
          <div className="flex justify-center items-center text-gray-400 text-sm mt-2">
            <Clock size={16} className="mr-1" />
            <span>{prediction.hours}</span>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="w-2/3 bg-gray-700 rounded-full h-4">
            <div
              className="bg-green-500 rounded-full h-4"
              style={{ width: `${prediction.confidence}%` }}
            ></div>
          </div>
          <span className="text-green-500 font-bold">{prediction.confidence}%</span>
        </div>
      </div>
    </div>
  );
};

const BetFree = ({ limit }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [predictions, setPredictions] = useState([]);
  const [currentDateTime, setCurrentDateTime] = useState(getCurrentDateTime());

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(getCurrentDateTime());
    }, 60000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const abetRef = ref(db, 'abet');

    onValue(abetRef, (snapshot) => {
      const data = snapshot.val();
      const loadedPredictions = Object.keys(data).map((key) => ({
        id: key,
        ...data[key],
      }));

      const todayPredictions = loadedPredictions.filter(
        (prediction) => prediction.date === currentDateTime.split(' ')[0]
      );

      setPredictions(todayPredictions);
    });
  }, [currentDateTime]);

  const handleSearch = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const filteredPredictions = predictions.filter((prediction) =>
    prediction.homeTeam.name.toLowerCase().includes(searchTerm) ||
    prediction.awayTeam.name.toLowerCase().includes(searchTerm) ||
    prediction.prediction.toLowerCase().includes(searchTerm)
  );

  // Limitar las predicciones si se define un límite
  const predictionsToShow = limit ? filteredPredictions.slice(0, limit) : filteredPredictions;

  return (
    <>
      <Helmet>
        <title>Consejos de Apuestas Deportivas Gratis | Pronósticos Diarios</title>
        <meta name="description" content="Obtén los mejores consejos de apuestas deportivas gratis. Pronósticos actualizados diariamente para fútbol y otros deportes. Mejora tus probabilidades de ganar hoy." />
        <meta name="keywords" content="apuestas deportivas, pronósticos gratis, consejos de apuestas, fútbol, predicciones deportivas" />
        <meta property="og:title" content="Predicciones deportivas Gratis | Pronósticos Diarios" />
        <meta property="og:description" content="Mejora tus probabilidades de ganar con nuestros consejos de apuestas deportivas gratis y actualizados diariamente." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://deportivospro.online/" />
        <link rel="canonical" href="https://deportivospro.online/" />
      </Helmet>
      <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white py-5 px-4 sm:px-6 lg:px-8">
          <div className='py-10'>
          <CarouselTransition/>
          </div>
        <div className="max-w-7xl mx-auto">
          <h1 className="text-4xl font-extrabold text-center mb-4">
            Consejos del día
          </h1>
          <p className="text-center text-gray-400 mb-10 flex justify-center items-center">
            <Calendar size={20} className="mr-2" />
            <span>{currentDateTime}</span>
          </p>

          <div className="mb-8 max-w-md mx-auto">
            <div className="relative">
              <input
                type="text"
                placeholder="Buscar equipos o pronósticos..."
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-full py-3 px-6 pl-12 focus:outline-none"
                value={searchTerm}
                onChange={handleSearch}
              />
              <Search className="absolute left-4 top-3.5 text-gray-400" size={20} />
            </div>
          </div>

          {predictionsToShow.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {predictionsToShow.map((prediction) => (
                <PredictionCard key={prediction.id} prediction={prediction} />
              ))}
            </div>
          ) : (
            <div className="text-center text-gray-400 mt-8 p-8 bg-gray-800 rounded-lg shadow-lg">
              <TrendingUp size={48} className="mx-auto mb-4" />
              <p className="text-xl font-semibold mb-2">No hay pronósticos disponibles en este momento</p>
              <p>Vuelve a consultar más tarde o explora nuestro historial de predicciones para obtener más información.</p>
            </div>
          )}
        </div>

       
      </div>
    </>
  );
};

export default BetFree;
