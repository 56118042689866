import React, { useState, useEffect } from "react";
import {
  Home,
  Search,
  ShoppingBag,
  Heart,
  User,
  HelpCircle,
} from "lucide-react";
import { ref, get } from "firebase/database";
import { database } from "../../Config/firebase";
import { useNavigate } from "react-router-dom"; // Añadido para la navegación
import MenuSearch from "./menu/Search";
import MenuCarrito from "./menu/Carrito";
import MenuPerfil from "./menu/Perfil";
import HomeHelp from "../Support/HomeHelp";

export default function HomeShop() {
  const [activeTab, setActiveTab] = useState("home");
  const [categories, setCategories] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Hook para la navegación

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const shopRef = ref(database, "/shop");
        const snapshot = await get(shopRef);

        if (snapshot.exists()) {
          const data = snapshot.val();
          setCategories(data.categories || []);
          setProducts(data.products || []);
        } else {
          setError("No se encontraron datos");
        }
      } catch (err) {
        setError("Error al cargar los datos: " + err.message);
        console.error("Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleProductClick = (productId) => {
    navigate(`/product-detail/${productId}`); // Navegar a la ruta de detalle del producto
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
          <p>Cargando...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen p-4">
        <div className="text-center text-red-600">
          <p className="mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col min-h-screen bg-blue-950">
      {/* Header con barra de búsqueda */}
      <header className="sticky top-0 z-50 bg-white shadow-sm p-4">
        <div className="relative">
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
          <input
            type="text"
            placeholder="Buscar productos..."
            className="w-full pl-10 pr-4 py-2 rounded-full border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 text-black"
          />
        </div>
      </header>

      <main className="flex-1 overflow-y-auto pb-16">
        {/* Contenido dinámico basado en activeTab */}
        {activeTab === "home" && (
          <>
            {/* Banner principal */}
            <div className="relative h-48 bg-blue-600 mb-6">
              <img
                src="https://marietatienda.wordpress.com/wp-content/uploads/2017/01/0006-42.png"
                alt="Banner promocional"
                className="w-full h-full object-cover"
              />
              <div className="absolute inset-0 bg-black bg-opacity-30 flex items-center justify-center">
                <div className="text-center text-white">
                  <h1 className="text-2xl font-bold mb-2">
                    Ofertas de Temporada
                  </h1>
                  <p className="text-sm">Hasta 50% de descuento</p>
                </div>
              </div>
            </div>

            {/* Categorías */}
            <section className="px-4 mb-8">
              <h2 className="text-lg font-semibold mb-4">Categorías</h2>
              <div className="grid grid-cols-4 gap-4">
                {categories.map((category) => (
                  <button
                    key={category.id}
                    className="flex flex-col items-center p-3 bg-white rounded-lg shadow-sm"
                  >
                    <span className="text-2xl mb-1">{category.icon}</span>
                    <span className="text-xs text-gray-600">
                      {category.name}
                    </span>
                  </button>
                ))}
              </div>
            </section>

            {/* Productos */}
            <section className="px-4 mb-8">
              <h2 className="text-lg font-semibold mb-4">Productos</h2>
              <div className="grid grid-cols-2 gap-4">
                {products.map((product) => (
                  <div
                    key={product.id}
                    className="bg-white rounded-lg shadow-sm overflow-hidden cursor-pointer"
                    onClick={() => navigate(`/product/${product.id}`)}
                  >
                    <div className="relative">
                      <img
                        src={product.image}
                        alt={product.name}
                        className="w-full h-40 object-cover"
                      />
                      {product.discount && (
                        <span className="absolute top-2 right-2 bg-red-500 text-white text-xs px-2 py-1 rounded">
                          -{product.discount}
                        </span>
                      )}
                    </div>
                    <div className="p-3">
                      <h3 className="text-lime-800 text-sm font-medium mb-1">
                        {product.name}
                      </h3>
                      <p className="text-blue-600 font-semibold">
                        ${product.price}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </>
        )}

        {/* Vista de búsqueda */}
        {activeTab === "search" && (
          <div className="p-4">
            {/* Vista de búsqueda */}
            <MenuSearch />
          </div>
        )}

        {/* Vista del carrito */}
        {activeTab === "cart" && (
          <div className="p-4">
            {/* Vista del carrito */}
            <MenuCarrito />
          </div>
        )}

        {/* Vista de favoritos */}
        {activeTab === "help" && (
          <div className="p-4">
            {/* Vista de favoritos */}
            <HomeHelp />
          </div>
        )}

        {/* Vista del perfil */}
        {activeTab === "profile" && (
          <div className="p-4">
            {/* Vista del perfil */}
            <MenuPerfil />
          </div>
        )}
      </main>

      {/* Barra de navegación inferior */}
      <nav className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200">
        <div className="grid grid-cols-5 gap-1">
          {[
            { icon: Home, label: "Inicio", id: "home" },
            { icon: Search, label: "Buscar", id: "search" },
            { icon: ShoppingBag, label: "Carrito", id: "cart" },
            { icon: HelpCircle, label: "Ayuda", id: "help" },
            { icon: User, label: "Perfil", id: "profile" },
          ].map(({ icon: Icon, label, id }) => (
            <button
              key={id}
              onClick={() => setActiveTab(id)}
              className={`flex flex-col items-center py-2 ${
                activeTab === id ? "text-blue-600" : "text-gray-500"
              }`}
            >
              <Icon className="h-6 w-6" />
              <span className="text-xs mt-1">{label}</span>
            </button>
          ))}
        </div>
      </nav>
    </div>
  );
}
