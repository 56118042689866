import React, { useState, useEffect, useRef } from "react";
import { ChevronRight, ChevronLeft } from "lucide-react";

// Implementación manual de Card y CardContent
const Card = ({ children, className = "" }) => (
  <div className={`border rounded-lg overflow-hidden shadow-lg ${className}`}>
    {children}
  </div>
);

const CardContent = ({ children, className = "" }) => (
  <div className={`p-0 ${className}`}>{children}</div>
);

const carouselItems = [
  {
    id: 1,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/img%2Fbaner%2FImagen%20de%20WhatsApp%202025-01-24%20a%20las%2023.34.18_997f41a6.jpg?alt=media&token=e8255dca-0b14-4b59-b099-4d8489c9ef49",
    actionUrl:
      "https://api.whatsapp.com/send?phone=573023580862&text=Hola%F0%9F%98%80%20Quiero%20hacer%20una%20recarga!%F0%9F%A4%91",
  },
  {
    id: 2,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/img%2Fbaner%2FIdeas-de-promociones-descuentos-para-vender-mas-en-tu-tienda-online.jpg?alt=media&token=6e793ddb-285e-43f7-b46c-66d089bd0726",
    actionUrl:
      "https://api.whatsapp.com/send?phone=573023580862&text=Hola%20Necesito%20un%20descuento!%F0%9F%99%8F%E2%98%BA%EF%B8%8F",
  },
  {
    id: 3,
    imageUrl:
      "https://firebasestorage.googleapis.com/v0/b/towin-fe4cc.appspot.com/o/img%2Fbaner%2Fwhatsapp%20grupo.jpeg?alt=media&token=c35167a3-c585-475f-b177-66748263b696",
    actionUrl: "https://chat.whatsapp.com/CLxqf0HbxgCEoQVrVu4lPn",
  },
];

export default function CarouselTransition() {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const intervalRef = useRef(null);

  const handleSlideClick = (url) => {
    window.location.href = url;
  };

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % carouselItems.length);
  };

  const prevSlide = () => {
    setCurrentSlide(
      (prev) => (prev - 1 + carouselItems.length) % carouselItems.length
    );
  };

  useEffect(() => {
    if (!isHovered) {
      intervalRef.current = setInterval(nextSlide, 3000); // Cambia cada 3 segundos
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [isHovered]);

  return (
    <div
      className="w-full max-w-4xl mx-auto relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="overflow-hidden relative">
        <div
          className="flex transition-transform duration-500"
          style={{ transform: `translateX(-${currentSlide * 100}%)` }}
        >
          {carouselItems.map((item) => (
            <div
              key={item.id}
              className="w-full flex-shrink-0 cursor-pointer hover:scale-105 transition-transform"
              onClick={() => handleSlideClick(item.actionUrl)}
            >
              <Card className="shadow-lg">
                <CardContent>
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="w-full h-64 object-cover rounded-t-lg"
                  />
                </CardContent>
              </Card>
            </div>
          ))}
        </div>
      </div>

      {/* Botones de navegación */}
      <button
        onClick={prevSlide}
        className="absolute left-0 top-1/2 -translate-y-1/2 bg-white/70 p-2 rounded-full shadow-md"
      >
        <ChevronLeft />
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 top-1/2 -translate-y-1/2 bg-white/70 p-2 rounded-full shadow-md"
      >
        <ChevronRight />
      </button>

      {/* Indicadores de progreso */}
      <div className="flex justify-center mt-4 space-x-2">
        {carouselItems.map((_, index) => (
          <button
            key={index}
            className={`
              h-2 w-2 rounded-full 
              ${currentSlide === index ? "bg-blue-500 w-6" : "bg-gray-300"}
              transition-all duration-300
            `}
          />
        ))}
      </div>
    </div>
  );
}
