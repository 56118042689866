import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { ref, get, update } from "firebase/database";
import { auth, database } from "../../Config/firebase"; // Asegúrate de importar ambos
import { ArrowLeft, Box, Clock, Heart, Share2, Shield, Star } from "lucide-react";

export default function ProductDetail() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [selectedImage, setSelectedImage] = useState(0);
  const [quantity, setQuantity] = useState(1);

  // Obtén el usuario autenticado
  const user = auth.currentUser;

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        setLoading(true);
        const productRef = ref(database, `/shop/products/${productId}`);
        const snapshot = await get(productRef);

        if (snapshot.exists()) {
          setProduct({
            ...snapshot.val(),
            sizes: ["S", "M", "L", "XL"],
            rating: 4.5,
            reviews: 128,
            deliveryTime: "2-4 días",
            warranty: "1 año",
            images: [
              snapshot.val().image,
              "/api/placeholder/400/500",
              "/api/placeholder/400/500",
              "/api/placeholder/400/500",
            ],
            details: [
              "Material de alta calidad",
              "Diseño exclusivo",
              "Fabricado con estándares premium",
              "Ideal para uso diario",
            ],
          });
        } else {
          setError("Producto no encontrado");
        }
      } catch (err) {
        setError("Error al cargar el producto: " + err.message);
        console.error("Error:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [productId]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-600 mb-4"></div>
          <p>Cargando producto...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center min-h-screen p-4 bg-gray-50">
        <div className="text-center text-red-600">
          <p className="mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="px-4 py-2 bg-blue-600 text-white rounded-lg"
          >
            Reintentar
          </button>
        </div>
      </div>
    );
  }

  if (!product) return null;

  const calculateFinalPrice = () => {
    if (product.discount) {
      const discountAmount =
        (product.price * parseFloat(product.discount)) / 100;
      return (product.price - discountAmount).toFixed(2);
    }
    return product.price;
  };

  const handlePurchase = async () => {
    const finalPrice = calculateFinalPrice() * quantity;
    if (user && user.uid) {
      const userRef = ref(database, `users/${user.uid}`);
      const userSnapshot = await get(userRef);
      const updatedSaldo = userSnapshot.val().saldo - finalPrice;

      // Guardar la compra en el historial del usuario
      const purchaseData = {
        productId: product.id,
        quantity: quantity,
        totalPrice: finalPrice,
        date: new Date().toISOString(),
      };

      const purchasesRef = ref(database, `users/${user.uid}/compras`);
      await update(purchasesRef, { [Date.now()]: purchaseData });

      // Actualizar el saldo del usuario
      await update(userRef, { saldo: updatedSaldo });
      alert("Compra realizada exitosamente!");
    } else {
      alert("Debes iniciar sesión para realizar la compra.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      {/* Header */}
      <header className="sticky top-0 z-50 bg-white shadow-sm">
        <div className="flex items-center justify-between p-4">
          <button className="p-2">
            <ArrowLeft className="h-6 w-6" />
          </button>
          <div className="flex space-x-4">
            <button className="p-2">
              <Share2 className="h-6 w-6" />
            </button>
            <button className="p-2">
              <Heart className="h-6 w-6" />
            </button>
          </div>
        </div>
      </header>

      <main className="flex-1 pb-20">
        {/* Galería de imágenes */}
        <div className="relative">
          <img
            src={product.images[selectedImage]}
            alt={product.name}
            className="w-full h-96 object-cover"
          />
          {product.discount && (
            <span className="absolute top-4 right-4 bg-red-500 text-white px-3 py-1 rounded-full">
              -{product.discount}%
            </span>
          )}
        </div>

        {/* Miniaturas */}
        <div className="flex gap-2 p-4 overflow-x-auto">
          {product.images.map((image, index) => (
            <button
              key={index}
              onClick={() => setSelectedImage(index)}
              className={`flex-shrink-0 w-20 h-20 rounded-lg overflow-hidden border-2 
                ${
                  selectedImage === index
                    ? "border-blue-500"
                    : "border-transparent"
                }`}
            >
              <img src={image} alt="" className="w-full h-full object-cover" />
            </button>
          ))}
        </div>

        {/* Información del producto */}
        <div className="p-4 bg-white">
          <div className="flex justify-between items-start mb-4">
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {product.name}
              </h1>
              <div className="flex items-center mt-2">
                <div className="flex items-center text-yellow-400">
                  {[...Array(5)].map((_, i) => (
                    <Star
                      key={i}
                      className={`h-4 w-4 ${
                        i < Math.floor(product.rating) ? "fill-current" : ""
                      }`}
                    />
                  ))}
                </div>
                <span className="ml-2 text-sm text-gray-600">
                  ({product.reviews} reseñas)
                </span>
              </div>
            </div>
            <div className="text-right">
              {product.discount && (
                <span className="text-sm text-gray-500 line-through">
                  ${product.price}
                </span>
              )}
              <p className="text-2xl font-bold text-blue-600">
                ${calculateFinalPrice()}
              </p>
            </div>
          </div>

          {/* Descripción */}
          <p className="text-gray-600 mb-6">{product.description}</p>

          {/* Tallas */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Tallas disponibles</h3>
            <div className="flex gap-3">
              {product.sizes.map((size) => (
                <button
                  key={size}
                  onClick={() => setSelectedSize(size)}
                  className={`w-12 h-12 rounded-full border-2 flex items-center justify-center
                    ${
                      selectedSize === size
                        ? "border-blue-500 text-blue-500"
                        : "border-gray-300 text-gray-600"
                    }`}
                >
                  {size}
                </button>
              ))}
            </div>
          </div>

          {/* Cantidad */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Cantidad</h3>
            <div className="flex items-center gap-4">
              <button
                onClick={() => setQuantity((prev) => Math.max(prev - 1, 1))}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
              >
                -
              </button>
              <span className="text-lg font-semibold">{quantity}</span>
              <button
                onClick={() => setQuantity((prev) => prev + 1)}
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-lg"
              >
                +
              </button>
            </div>
          </div>

          {/* Detalles del producto */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">
              Detalles del producto
            </h3>
            <ul className="space-y-2 text-gray-600">
              {product.details.map((detail, index) => (
                <li key={index} className="flex items-center">
                  <Box className="w-5 h-5 text-blue-600" />
                  <span className="ml-2">{detail}</span>
                </li>
              ))}
            </ul>
          </div>

          {/* Garantía */}
          <div className="mb-6">
            <h3 className="text-lg font-semibold mb-3">Garantía</h3>
            <div className="flex items-center text-gray-600">
              <Shield className="w-5 h-5 text-green-600" />
              <span className="ml-2">{product.warranty}</span>
            </div>
          </div>

          {/* Tiempo de entrega */}
          <div>
            <h3 className="text-lg font-semibold mb-3">Tiempo de entrega</h3>
            <div className="flex items-center text-gray-600">
              <Clock className="w-5 h-5 text-orange-600" />
              <span className="ml-2">{product.deliveryTime}</span>
            </div>
          </div>

          {/* Botón de compra */}
          <button
            onClick={handlePurchase}
            className="w-full py-3 mt-6 bg-blue-600 text-white rounded-lg"
          >
            Comprar ahora
          </button>
        </div>
      </main>
    </div>
  );
}
