import React, { useState, useEffect } from "react";
import { getAuth } from "firebase/auth"; // Importa Firebase Auth
import html2canvas from "html2canvas"; // Librería para convertir elementos en imágenes

export default function Recharge() {
  const [coinCount, setCoinCount] = useState(0);
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState("");
  const COIN_VALUE = 100; // Cada moneda equivale a 100 COP

  // Obtener datos del usuario logueado automáticamente
  useEffect(() => {
    const auth = getAuth(); // Obtener la instancia de autenticación
    const user = auth.currentUser; // Usuario autenticado actualmente

    if (user) {
      setUserEmail(user.email); // Email del usuario
      setUserId(user.uid); // UID del usuario
    } else {
      console.error("No hay ningún usuario logueado.");
    }
  }, []);

  const handleCoinChange = (e) => {
    const count = parseInt(e.target.value) || 0;
    setCoinCount(count);
  };

  const handleRecharge = async () => {
    if (coinCount > 0) {
      const totalAmount = coinCount * COIN_VALUE;

      // Generar imagen del recibo
      const receiptElement = document.getElementById("receipt");
      html2canvas(receiptElement).then((canvas) => {
        const image = canvas.toDataURL("image/png");

        // Redirigir a WhatsApp con la imagen generada
        const whatsappUrl = `https://wa.me/573023580862?text=${encodeURIComponent(
          `Hola, adjunto mi recibo para la recarga.`
        )}`;
        const link = document.createElement("a");
        link.href = whatsappUrl;
        link.target = "_blank";

        // Simula adjuntar la imagen (no se puede adjuntar automáticamente en WhatsApp Web)
        console.log("Imagen generada:", image);

        // Abrir WhatsApp
        link.click();
      });
    }
  };

  const totalAmount = coinCount * COIN_VALUE;

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-blue-100 flex items-center justify-center p-4">
      <div className="w-full max-w-md bg-white shadow-2xl rounded-2xl overflow-hidden transform transition-all hover:scale-105">
        <div className="bg-blue-600 text-white p-6 text-center">
          <h2 className="text-2xl font-bold tracking-wide">
            Recarga de Monedas
          </h2>
        </div>

        <div className="p-6 space-y-6">
          <div>
            <label
              htmlFor="coinInput"
              className="block text-sm font-medium text-gray-700 mb-2"
            >
              Número de Monedas
            </label>
            <div className="relative">
              <input
                id="coinInput"
                type="number"
                min="0"
                value={coinCount}
                onChange={handleCoinChange}
                className="w-full px-4 py-3 border-2 text-black border-blue-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 transition-all"
                placeholder="Ingrese cantidad de monedas"
              />
            </div>
          </div>

          <div
            id="receipt"
            className="p-4 border rounded-lg bg-white shadow-md space-y-2"
          >
            <h3 className="text-lg font-bold text-gray-800">
              Recibo de Recarga
            </h3>
            <p className="text-sm text-gray-600">
              <strong>Email:</strong> {userEmail}
            </p>
            <p className="text-sm text-gray-600">
              <strong>ID Usuario:</strong> {userId}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Cantidad de Monedas:</strong> {coinCount}
            </p>
            <p className="text-sm text-gray-600">
              <strong>Precio Total:</strong>{" "}
              {new Intl.NumberFormat("es-CO", {
                style: "currency",
                currency: "COP",
              }).format(totalAmount)}
            </p>
          </div>

          <button
            onClick={handleRecharge}
            disabled={coinCount === 0}
            className={`w-full py-3 rounded-lg transition-all duration-300 ${
              coinCount === 0
                ? "bg-gray-300 text-gray-500 cursor-not-allowed"
                : "bg-green-500 text-white hover:bg-green-600 active:bg-green-700"
            }`}
          >
            Enviar Recarga por WhatsApp
          </button>
        </div>

        <div className="bg-gray-100 p-4 text-center text-sm text-gray-500">
          Cada moneda vale {COIN_VALUE} COP
        </div>
      </div>
    </div>
  );
}
