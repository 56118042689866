import React, { useState, useEffect, useRef } from "react";
import { getDatabase, ref, push, onChildAdded, get } from "firebase/database";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { Camera, Send, X, Loader2 } from "lucide-react";

export default function SupportChat() {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [user, setUser] = useState(null);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        loadUserName(currentUser.uid);
        loadMessages(currentUser.uid);
      } else {
        window.location.href = "/login";
      }
    });

    return () => unsubscribe();
  }, []);

  const loadUserName = async (userId) => {
    try {
      const db = getDatabase();
      const userRef = ref(db, `usuarios/${userId}`);
      const snapshot = await get(userRef);
      if (snapshot.exists()) {
        const userData = snapshot.val();
        setUserName(userData.name || "Usuario");
      }
    } catch (error) {
      console.error("Error loading user name:", error);
    }
  };

  const loadMessages = (userId) => {
    const db = getDatabase();
    const chatRef = ref(db, `chats/${userId}`);
    onChildAdded(chatRef, (snapshot) => {
      const message = snapshot.val();
      setMessages((prevMessages) => [...prevMessages, message]);
    });
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size > 5 * 1024 * 1024) {
        alert("La imagen no debe superar los 5MB");
        return;
      }

      setImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setImage(null);
    setImagePreview(null);
    const input = document.getElementById("image-upload");
    if (input) input.value = "";
  };

  const uploadImage = async () => {
    if (image) {
      const storage = getStorage();
      const imageRef = storageRef(
        storage,
        `chat-images/${Date.now()}_${image.name}`
      );
      await uploadBytes(imageRef, image);
      return getDownloadURL(imageRef);
    }
    return null;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if ((!newMessage.trim() && !image) || isLoading) return;

    setIsLoading(true);
    try {
      const imageUrl = await uploadImage();
      const db = getDatabase();
      const chatRef = ref(db, `chats/${user.uid}`);
      await push(chatRef, {
        text: newMessage.trim(),
        sender: user.uid,
        timestamp: Date.now(),
        userName: userName,
        imageUrl: imageUrl,
      });

      setNewMessage("");
      removeImage();
    } catch (error) {
      console.error("Error sending message:", error);
      alert("Error al enviar el mensaje. Por favor, intente nuevamente.");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const scrollToBottom = () => {
      if (messageContainerRef.current) {
        const { scrollHeight, clientHeight } = messageContainerRef.current;
        messageContainerRef.current.scrollTop = scrollHeight - clientHeight;
      }
    };

    scrollToBottom();
  }, [messages]);

  if (!user) {
    return (
      <div className="flex h-screen items-center justify-center bg-gray-900">
        <Loader2 className="h-8 w-8 animate-spin text-blue-500" />
      </div>
    );
  }

  return (
    <div className="flex h-screen flex-col bg-gray-900">
      {/* Header */}
      <div className="fixed top-0 z-10 w-full bg-gray-800 p-4 shadow-md">
        <h2 className="text-lg font-semibold text-white">Soporte Técnico</h2>
      </div>

      {/* Messages Area */}
      <div
        ref={messageContainerRef}
        className="flex-1 overflow-y-auto p-4 pt-16 pb-32"
      >
        {messages.map((message, index) => (
          <div
            key={index}
            className={`mb-4 flex ${
              message.sender === user.uid ? "justify-end" : "justify-start"
            }`}
          >
            <div
              className={`max-w-[80%] rounded-lg p-3 ${
                message.sender === user.uid
                  ? "bg-blue-500 text-white"
                  : "bg-white text-gray-800"
              }`}
            >
              <div className="mb-1 text-sm font-semibold">
                {message.userName}
              </div>
              {message.text && (
                <div className="break-words">{message.text}</div>
              )}
              {message.imageUrl && (
                <div className="mt-2 overflow-hidden rounded-lg">
                  <img
                    src={message.imageUrl}
                    alt="Mensaje"
                    className="max-h-64 w-auto object-contain"
                    loading="lazy"
                  />
                </div>
              )}
              <div className="mt-1 text-right text-xs opacity-75">
                {new Date(message.timestamp).toLocaleTimeString()}
              </div>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Message Input Form */}
      <form
        onSubmit={sendMessage}
        className="fixed bottom-0 left-0 right-0 bg-gray-800 p-4 shadow-lg"
      >
        {imagePreview && (
          <div className="mb-2 flex items-center gap-2">
            <div className="relative h-16 w-16">
              <img
                src={imagePreview}
                alt="Preview"
                className="h-full w-full rounded object-cover"
              />
              <button
                type="button"
                onClick={removeImage}
                className="absolute -right-2 -top-2 rounded-full bg-red-500 p-1 text-white hover:bg-red-600"
              >
                <X size={16} />
              </button>
            </div>
          </div>
        )}
        <div className="flex items-center gap-2">
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
            id="image-upload"
          />
          <label
            htmlFor="image-upload"
            className="cursor-pointer rounded-full p-2 hover:bg-gray-700"
          >
            <Camera size={24} className="text-blue-400" />
          </label>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Escribe tu mensaje..."
            className="flex-1 rounded-lg bg-gray-700 px-4 py-2 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isLoading}
          />
          <button
            type="submit"
            disabled={isLoading || (!newMessage.trim() && !image)}
            className="rounded-lg bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 disabled:opacity-50"
          >
            {isLoading ? (
              <Loader2 size={20} className="animate-spin" />
            ) : (
              <Send size={20} />
            )}
          </button>
        </div>
      </form>
    </div>
  );
}
