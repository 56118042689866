import React, { useState, useEffect } from "react";
import { ChevronRight, Mail, User, Wallet, X } from "lucide-react";
import useUserData from "../../Hooks/useUserData";
import { get, getDatabase, ref, update } from "firebase/database";

const WalletView = () => {
  const { userData, loading, error, updateUserData } = useUserData();
  const [amount, setAmount] = useState("");
  const [recipientEmail, setRecipientEmail] = useState("");
  const [recipientName, setRecipientName] = useState("");
  const [transactionHistory, setTransactionHistory] = useState([]);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);
  const [activeTab, setActiveTab] = useState("transfer");
  const [isLoading, setIsLoading] = useState(false);
  const [notification, setNotification] = useState({
    show: false,
    type: "",
    message: "",
  });

  useEffect(() => {
    if (userData) {
      const sortedTransactions = [...(userData.transacciones || [])].sort(
        (a, b) => new Date(b.fecha) - new Date(a.fecha)
      );
      setTransactionHistory(sortedTransactions);
    }
  }, [userData]);

  const showNotification = (type, message) => {
    setNotification({ show: true, type, message });
    setTimeout(
      () => setNotification({ show: false, type: "", message: "" }),
      5000
    );
  };

  const Notification = () => {
    if (!notification.show) return null;

    const bgColor =
      notification.type === "error" ? "bg-red-500" : "bg-green-500";

    return (
      <div
        className={`fixed top-4 right-4 ${bgColor} text-white p-4 rounded-lg shadow-lg z-50 animate-fade-in-down flex items-center justify-between max-w-md`}
      >
        <span>{notification.message}</span>
        <button
          onClick={() =>
            setNotification({ show: false, type: "", message: "" })
          }
          className="ml-4 hover:text-gray-200"
        >
          <X size={20} />
        </button>
      </div>
    );
  };

  const handleEmailCheck = async () => {
    if (!recipientEmail) {
      showNotification("error", "Por favor ingrese un correo electrónico");
      return;
    }

    if (recipientEmail.toLowerCase() === userData.email.toLowerCase()) {
      showNotification(
        "error",
        "No puedes transferir dinero a tu propia cuenta"
      );
      return;
    }

    setIsLoading(true);
    const db = getDatabase();
    const usersRef = ref(db, "usuarios");

    try {
      const snapshot = await get(usersRef);
      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUser = Object.values(users).find(
          (user) => user.email.toLowerCase() === recipientEmail.toLowerCase()
        );
        if (recipientUser) {
          setRecipientName(recipientUser.name || "Usuario");
          setStep(2);
          showNotification("success", "Usuario encontrado");
        } else {
          showNotification("error", "El usuario no existe en el sistema");
        }
      }
    } catch (error) {
      console.error("Error al verificar el usuario:", error);
      showNotification(
        "error",
        "Error al verificar el usuario. Por favor, inténtelo de nuevo"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleTransfer = async () => {
    if (!amount || isNaN(amount) || amount <= 0) {
      showNotification("error", "Por favor ingrese una cantidad válida");
      return;
    }

    setIsLoading(true);
    const transferAmount = parseFloat(amount);
    const newSenderSaldo = (userData.saldo || 0) - transferAmount;

    if (newSenderSaldo < 0) {
      showNotification("error", "Saldo insuficiente");
      setIsLoading(false);
      return;
    }

    try {
      const db = getDatabase();
      const usersRef = ref(db, "usuarios");
      const snapshot = await get(usersRef);

      if (snapshot.exists()) {
        const users = snapshot.val();
        const recipientUserEntry = Object.entries(users).find(
          ([, user]) =>
            user.email.toLowerCase() === recipientEmail.toLowerCase()
        );

        if (recipientUserEntry) {
          const [recipientKey, recipientData] = recipientUserEntry;
          const recipientRef = ref(db, `usuarios/${recipientKey}`);
          const newRecipientSaldo = (recipientData.saldo || 0) + transferAmount;

          const timestamp = new Date().toLocaleString();

          const newSenderTransaction = {
            tipo: "Transferencia enviada",
            monto: -transferAmount,
            fecha: timestamp,
            destinatario: recipientEmail,
            mensaje: message || "",
          };

          const newRecipientTransaction = {
            tipo: "Transferencia recibida",
            monto: transferAmount,
            fecha: timestamp,
            remitente: userData.email,
            mensaje: message || "",
          };

          await Promise.all([
            updateUserData({
              saldo: newSenderSaldo,
              transacciones: [
                newSenderTransaction,
                ...(userData.transacciones || []),
              ],
            }),
            update(recipientRef, {
              saldo: newRecipientSaldo,
              transacciones: [
                newRecipientTransaction,
                ...(recipientData.transacciones || []),
              ],
            }),
          ]);

          setAmount("");
          setRecipientEmail("");
          setRecipientName("");
          setMessage("");
          setStep(1);
          showNotification("success", "Transferencia realizada con éxito");
        }
      }
    } catch (error) {
      console.error("Error al realizar la transferencia:", error);
      showNotification(
        "error",
        "Error al realizar la transferencia. Por favor, inténtelo de nuevo"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const TransactionHistory = () => (
    <div className="space-y-4 mt-6">
      {transactionHistory.length === 0 ? (
        <div className="text-center text-gray-400 py-8">
          No hay transacciones para mostrar
        </div>
      ) : (
        transactionHistory.map((transaction, index) => (
          <div
            key={index}
            className="bg-gray-800 p-4 rounded-lg transition-all duration-300 hover:bg-gray-700"
          >
            <div className="flex justify-between items-center">
              <div>
                <p className="text-white font-medium">{transaction.tipo}</p>
                <p className="text-gray-400 text-sm">{transaction.fecha}</p>
                {transaction.destinatario && (
                  <p className="text-gray-400 text-sm">
                    Para: {transaction.destinatario}
                  </p>
                )}
                {transaction.remitente && (
                  <p className="text-gray-400 text-sm">
                    De: {transaction.remitente}
                  </p>
                )}
              </div>
              <p
                className={`text-lg font-bold ${
                  transaction.monto > 0 ? "text-green-400" : "text-red-400"
                }`}
              >
                {transaction.monto > 0 ? "+" : ""}$
                {Math.abs(transaction.monto).toLocaleString()}
              </p>
            </div>
            {transaction.mensaje && (
              <p className="text-gray-400 mt-2 text-sm">
                Mensaje: {transaction.mensaje}
              </p>
            )}
          </div>
        ))
      )}
    </div>
  );

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-900">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-900">
        <div className="bg-red-500 text-white p-4 rounded-lg shadow-lg animate-bounce">
          Error: {error}
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-900 p-4 md:p-6">
      <Notification />
      <div className="max-w-2xl mx-auto">
        <div className="bg-gray-800 rounded-2xl shadow-xl p-6 md:p-8">
          <h1 className="text-3xl font-bold text-white mb-8 text-center">
            Mi Billetera
          </h1>

          <div className="bg-gray-700 rounded-xl p-6 mb-8">
            <div className="flex items-center justify-between mb-4">
              <div className="flex items-center gap-3">
                <Wallet className="w-6 h-6 text-blue-400" />
                <span className="text-gray-200 font-medium">
                  Saldo Disponible
                </span>
              </div>
              <ChevronRight className="w-5 h-5 text-gray-400" />
            </div>
            <div className="text-4xl font-bold text-green-400 ml-9">
              ${userData.saldo?.toLocaleString() || "0"}
            </div>
          </div>

          <div className="bg-gray-700 rounded-xl p-6 mb-8">
            <div className="space-y-4">
              <div className="flex items-center gap-3">
                <User className="w-5 h-5 text-blue-400" />
                <span className="text-gray-200">
                  {userData.nombre || "Usuario"}
                </span>
              </div>
              <div className="flex items-center gap-3">
                <Mail className="w-5 h-5 text-blue-400" />
                <span className="text-gray-200">
                  {userData.email || "usuario@ejemplo.com"}
                </span>
              </div>
            </div>
          </div>

          <div className="flex space-x-2 mb-6">
            <button
              onClick={() => setActiveTab("transfer")}
              className={`flex-1 py-3 px-4 rounded-lg font-medium transition-all duration-300 ${
                activeTab === "transfer"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-700 text-gray-400 hover:bg-gray-600"
              }`}
            >
              Transferencias
            </button>
            <button
              onClick={() => setActiveTab("history")}
              className={`flex-1 py-3 px-4 rounded-lg font-medium transition-all duration-300 ${
                activeTab === "history"
                  ? "bg-blue-500 text-white"
                  : "bg-gray-700 text-gray-400 hover:bg-gray-600"
              }`}
            >
              Historial
            </button>
          </div>

          {activeTab === "transfer" && (
            <div className="space-y-6">
              {step === 1 ? (
                <div className="space-y-4">
                  <input
                    type="email"
                    placeholder="Correo del destinatario"
                    className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                    value={recipientEmail}
                    onChange={(e) => setRecipientEmail(e.target.value)}
                  />
                  <button
                    onClick={handleEmailCheck}
                    disabled={isLoading}
                    className={`w-full p-4 rounded-lg font-medium text-white transition-all duration-300 ${
                      isLoading
                        ? "bg-gray-600 cursor-not-allowed"
                        : "bg-blue-500 hover:bg-blue-600"
                    }`}
                  >
                    {isLoading ? (
                      <div className="flex items-center justify-center">
                        <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                        Verificando...
                      </div>
                    ) : (
                      "Verificar Destinatario"
                    )}
                  </button>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="bg-gray-700 p-4 rounded-lg">
                    <p className="text-gray-400">Destinatario:</p>
                    <p className="text-white">
                      {recipientName} ({recipientEmail})
                    </p>
                  </div>
                  <input
                    type="number"
                    placeholder="Cantidad a transferir"
                    className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                  />
                  <input
                    type="text"
                    placeholder="Mensaje (opcional)"
                    className="w-full p-4 rounded-lg bg-gray-700 text-white placeholder-gray-400 border border-gray-600 focus:outline-none focus:border-blue-500 transition-colors"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                  <div className="flex space-x-4">
                    <button
                      onClick={() => {
                        setStep(1);
                        setRecipientEmail("");
                        setRecipientName("");
                      }}
                      className="flex-1 p-4 rounded-lg font-medium text-white bg-gray-600 hover:bg-gray-500 transition-all duration-300"
                    >
                      Cancelar
                    </button>
                    <button
                      onClick={handleTransfer}
                      disabled={isLoading}
                      className={`flex-1 p-4 rounded-lg font-medium text-white transition-all duration-300 ${
                        isLoading
                          ? "bg-green-600 cursor-not-allowed"
                          : "bg-green-500 hover:bg-green-600"
                      }`}
                    >
                      {isLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="animate-spin h-5 w-5 border-2 border-white border-t-transparent rounded-full mr-2"></div>
                          Procesando...
                        </div>
                      ) : (
                        "Transferir"
                      )}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}

          {activeTab === "history" && <TransactionHistory />}
        </div>
      </div>
    </div>
  );
};

export default WalletView;
